import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import {IconButton} from "@mui/material";
import {appName} from "../../config";
import {useContext} from "react";
import {GlobalContext} from "../../state/global";
import useAxios from "axios-hooks";
import SettingsService from "../../services/settings.service";

export default function CheckinAppBar({handleDialogOpen}) {
    const [{data: dataSettings, loading, error}] = useAxios(
        SettingsService.settingsUrl(), {method: "GET", useCache: false, manual: false}
    )

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" color={'secondary'}>
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Check in
                    </Typography>
                    <Typography variant={'body1'} textAlign="center">
                        {dataSettings?.settings?.eventName || ''}
                    </Typography>
                    {<IconButton
                        size="large"
                        aria-label="cerca iscritto"
                        color="inherit"
                        onClick={handleDialogOpen}
                    >
                        <PersonSearchIcon />
                    </IconButton>}
                </Toolbar>
            </AppBar>
        </Box>
    );
}
