import firebase from 'firebase/compat/app'
import 'firebase/compat/database'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
// TODO: Add SDKs for Firebase products that you want to use

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBk5C-riM4U8D0laU1jkor-IXryTD9wsBU",
    authDomain: "winston-demo-381913.firebaseapp.com",
    projectId: "winston-demo-381913",
    storageBucket: "winston-demo-381913.appspot.com",
    messagingSenderId: "641392212608",
    appId: "1:641392212608:web:a1bb979b49c541b3d5c465"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

const auth = firebase.auth()

export {auth, firebase}