import React, {useContext, useEffect, useMemo, useState} from "react";
import {GlobalContext} from "../../state/global";
import {filter, find, remove, union} from "lodash";
import ColumnsService, {commonFields} from "../../services/columns.service";
import {fieldTypes} from "../../services/utils";
import {Autocomplete, Chip, Paper, Stack, styled, TextField, Typography} from "@mui/material";
import {lighten} from "@mui/system/colorManipulator";
import {customTheme} from "../../theme/customTheme";
import CustomTooltip from "../../components/CustomTooltip";
import IconButton from "@mui/material/IconButton";
import {Cancel, Help} from "@mui/icons-material";
import {forms} from "../../services/settings.service";
import MenuItem from "@mui/material/MenuItem";

export const GroupHeader = styled('div')(({theme}) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 8px',
    color: theme.palette.accent.main,
    fontSize: '0.8rem',
    backgroundColor: lighten(theme.palette.accent.main, 0.9)
}));

export const GroupItems = styled('ul')({
    padding: 0,
});

const CheckinFieldsToShow = ({settings, setSettings}) => {
    const maxFields = 4

    const [globalState, dispatch] = useContext(GlobalContext)

    const [state, setState] = useState({
        selectedForm: find(globalState.dataStructures, ['main', true]),
        checkInFields: []
    })

    useEffect(() => {
        if (state.checkInFields.length > 0) {
            setSettings({
                ...settings,
                checkInFields: union(settings.checkInFields, state.checkInFields.map((field) => (field.id)))
            })
        }
    }, [state.checkInFields])

    const optionsFields = useMemo(() => {
        if (!globalState.dataStructures)
            return []

        const mainForm = !!state.selectedForm ?
            find(globalState.dataStructures, ['id', state.selectedForm?.id])
            : find(globalState.dataStructures, ['main', true])

        let res = filter(
            ColumnsService.getFieldsFromForm(mainForm),
            function (o) {
                return o.type !== fieldTypes.CUSTOM.type
                    && o.type !== fieldTypes.ACCESS_TYPE.type
                    && o.type !== fieldTypes.URL.type
            }
        )
        res = filter(res, function (o) {
            return !find(commonFields, ['id', o.id])
        })

        return filter(res, function (o) {
            return !find(settings.checkInFields, ['id', o.id])//.includes(o.id)
        })
    }, [state.selectedForm, globalState.dataStructures, settings]);

    function handleChangeSelectedForm(event) {
        setState({...state, selectedForm: event.target.value})
    }

    function handleDeleteCheckInField(field) {
        remove(settings.checkInFields, function (o) {
            return o.id === field
        })

        setSettings({
            ...settings,
            checkInFields: settings.checkInFields
        })
    }

    return (
        <Stack py={1} px={2} spacing={3}>
            <Stack p={2} direction={'row'} alignItems={'baseline'} spacing={1} component={Paper}
                   sx={{background: lighten(customTheme.palette.primary.main, 0.9)}}
            >
                <CustomTooltip
                    title={"Verranno mostrati insieme ai dati obbligatori Nome, Cognome e E-mail alla scansione del QR code"}
                    children={<IconButton size={'small'}>
                        <Help color={'disabled'}
                              fontSize={'small'}/>
                    </IconButton>}
                />
                <Typography variant={'body1'} fontWeight={'bold'}>Dati opzionali mostrati al check-in: </Typography>
                <Stack direction={'row'} alignItems={'center'} flexWrap={'wrap'}>
                    {
                        settings.checkInFields?.map((field, i) => (
                            <Chip
                                key={i}
                                size={'small'}
                                //variant={'accent-outlined'}
                                sx={{marginBottom: 0.5, marginRight: 0.5}}
                                color={"primary"}
                                label={field.label}
                                deleteIcon={<Cancel/>}
                                onDelete={() => handleDeleteCheckInField(field.id)}
                            />
                        ))
                    }
                </Stack>
            </Stack>
            <Stack direction={"row"} spacing={2}>
                <TextField variant={'outlined'} size={'small'}
                           select
                           sx={{width: '60%'}}
                           id={'form'}
                           name={'form'}
                           label={'Form'}
                           defaultValue={find(globalState.dataStructures, ['main', true])}
                           value={state.selectedForm}
                           onChange={handleChangeSelectedForm}
                >
                    {
                        filter(Object.values(globalState.dataStructures||[]), function (o) {
                            return o.id !== forms.CHECKIN.id
                        }).map((option) => (
                            <MenuItem key={option.label} value={option} children={
                                <Stack direction={'row'} spacing={1}>
                                    <div>{option.label}</div>
                                </Stack>
                            }/>
                        ))}
                </TextField>
                <Stack width={'100%'} direction={'row'} alignItems={'flex-start'}>
                    <Autocomplete
                        fullWidth
                        size={'small'}
                        disableCloseOnSelect
                        groupBy={(option) => option.section}
                        onChange={(event, newValue) => {
                            if (newValue && settings.checkInFields.length < maxFields)
                                setSettings({
                                    ...settings,
                                    checkInFields: settings.checkInFields.concat([newValue])
                                })
                        }}
                        renderInput={(params) =>
                            <TextField {...params}
                                       label="Aggiungi campi da mostrare in fase di check-in"
                                       helperText={`Puoi aggiungerne fino a ${maxFields}`}
                            />}
                        options={optionsFields}
                        renderGroup={(params) => (
                            <li key={params.key}>
                                <GroupHeader>{params.group}</GroupHeader>
                                <GroupItems>{params.children}</GroupItems>
                            </li>
                        )}
                    />
                </Stack>
            </Stack>
        </Stack>
    );
}

export default CheckinFieldsToShow
