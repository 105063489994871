import {Diversity1, LocalPolice, WorkspacePremium} from "@mui/icons-material";

//export const API_URL = "https://winston-demo-api-z6exfu5l4q-ew.a.run.app/" // PROD
export const API_URL = "https://winston-demo-dev-api-z6exfu5l4q-ew.a.run.app/" // DEV
//export const API_URL = "http://localhost:8080/";

export const appName = process.env.REACT_APP_APP_NAME || 'Dashboard'

export const roles = {
    ADMIN: {
        icon: <LocalPolice/>,
        label: "Admin",
        id: "ADMIN"
    },
    AGENCY: {
        icon: <Diversity1/>,
        label: "Agenzia",
        id: "AGENCY"
    },
    CLIENT: {
        icon: <WorkspacePremium/>,
        label: "Cliente",
        id: "CLIENT"
    }
}